<template>
	<a target="_blank" rel="noopener noreferrer" :href="getUrl({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/hoteliers';

const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

const props = defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	packageid: { type: String, default: '' },
	promotioncode: { type: String, default: '' },
	roomid: { type: String, default: '' },
	nights: { type: String, default: '' },
});

const getUrl = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value && bookingEngine.value.getUrl(options);
};

onMounted(() => {
	bookingEngine.value = new BookingEngine({
		hotelID: 443,
	});
});
</script>

<style lang="scss" scoped>
a {
	color: #fff;
	cursor: pointer;

	&:hover {
		text-decoration: none;
	}
}
</style>
